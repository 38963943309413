class ZIDXAccountSummary implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountSummaryContainer";
    }
    getMatchURL(){
        return "/account/summary";
    }
    render(){
        // console.log("payment summary.");
    }
}